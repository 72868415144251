<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage question bank</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Question bank
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn v-if="checkIsAccessible('question-bank-category', 'create')"
                      @click="manageQuestion()"
                      x-large
                      text>
                    Manage question category
                  </v-btn>&nbsp;&nbsp;
                  <v-btn   v-if="checkIsAccessible('question-bank', 'create')"  @click="createNewQuestion" class="mt-1 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add question bank
                  </v-btn>&nbsp;

                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="">
              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th class="px-3 wrap-column" >Questions</th>
                  <th class="px-3 wrap-column">Program title</th>
                  <th class="px-3 wrap-column">Category</th>
                  <th class="px-3 wrap-column">Correct answer</th>
                  <th class="px-3 wrap-column">Type</th>
                  <th class="pr-3 text-center">Action</th>
                </tr>
                </thead>
                <template>
                  <tr v-if="question_banks.length > 0" v-for="(item, index) in question_banks" :key="index" >
                    <td class="px-3 wrap-column">
                      <span v-html="item.question">

                      </span>&nbsp;&nbsp; <i class="fas fa-circle"
                                             :class="item.is_active?'dot-active':'dot-inactive'"></i>
                    </td>
                    <td class="px-3 wrap-column">
                      <div  v-show="item.program_ids" v-for="(program, index) of item.program_titles" :key="index">
                        <v-chip  label class="ma-2" small> {{ program }} </v-chip><br>
                      </div>
                    </td>
                    <td class="px-3 wrap-column">
                      <div class="">
                        {{ item.category_title ? item.category_title:'NA' }}
                      </div>
                    </td>
                    <td class="px-3 wrap-column">
                      <div class="">
                        {{ item.answer ? item.answer:'NA' }}
                      </div>
                    </td>
                    <td class="px-3 wrap-column">
                      <div class="">
                        {{ item.type ? item.type_text:'NA' }}
                      </div>
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown size="sm" variant="link"
                                    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                    no-caret right no-flip>
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">

                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('question-bank', 'edit')">
                              <a href="#" class="navi-link"
                                 @click="editQuestion(item.id)">
                                                            <span class="navi-icon">
                                                              <i class="flaticon-edit"></i>
                                                            </span>
                                <span class="navi-text">  Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('question-bank', 'delete')">
                              <a href="#" class="navi-link"
                                 @click="deleteQuestion(item.id)">
                                                         <span class="navi-icon">
                                                              <i class="fas fa-trash"></i>
                                                            </span>
                                <span class="navi-text">  Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="question_banks.length == 0">
                    <td class="text-center" colspan="6">
                      <strong>
                        No data available.
                      </strong>

                    </td>
                  </tr>
                </template>
              </table>
              <b-pagination
                  @input="getAllQuestions()"    class="pull-right mt-7"
                  v-model="page" v-if="question_banks.length > 0"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
       </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import {QuizMixin} from "@/mixins/QuizMixin";

export default {
  name: "QuestionBanks",
  mixins: [QuizMixin],
  components: {},
  data() {
    return {
      expanded: [],
      singleExpand: false,
      loading: false,
      headers: [
        {
          text: 'Questions',
          align: 'start',
          sortable: false,
          value: 'question',
        },
        {text: 'Program title', value: 'program_titles'},
        {text: 'Category', value: 'category'},
        {text: 'Correct answer', value: 'answer'},
        {text: 'Type', value: 'type'},
        {text: 'Action', value: 'action'},
      ],
      question_banks: [],
      total: null,
      perPage: null,
      page: null,
    }
  },
  mounted() {
   this.getAllQuestions()
  },
  methods: {
    getAllQuestions(){
      this.getQuestions();
    },
    createNewQuestion(){
      this.$router.push({
        name: "question-bank-new",

      });
    },
    manageQuestion(){
      this.$router.push({
        name: "question-bank-category",

      });
    },
    editQuestion(id) {
      this.$router.push({name: 'question-bank-update', params: {id: id}})
    },
    deleteQuestion(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            this.deleteQuestionBank(id);
          }
        }
      });
    }
  }
};
</script>
